import React from 'react';
import './App.css';
import { Avatar, Button, CircularProgress, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemText,
  ListSubheader, Paper, TablePagination, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import GroupIcon from '@material-ui/icons/Group'
import PersonIcon from '@material-ui/icons/Person'
import SearchIcon from '@material-ui/icons/Search'

/* App goals:
- DONE: create template repo in Github that includes an ADO pipeline and sample working Terraform code
- DONE: authenticate with Github
- DONE: take a repo name, create Github repo
- DONE: add a README.md file to the new repo
  - stretch goal: assign permissions to the repo
- DONE: authenticate with ADO
- create build pipeline in ADO from new Github repo
- create release pipeline from template?
*/

// TODO Think about using branch-based environments in release pipelines

const useStyles = makeStyles((theme) => ({
  list: {
    backgroundColor: theme.palette.background.paper,
    // position: 'relative',
    // overflow: 'auto',
    // maxHeight: 300,
  },
  centered: {
    textAlign: 'center'
  }
}))

const axios = require('axios')
const baseUrl = window.location.origin

export default function App() {
  const classes = useStyles()

  const [displayedGithubMembers, setDisplayedGithubMembers] = React.useState(null)
  const [membersPage, setMembersPage] = React.useState(0)
  const [membersPerPage, setMembersPerPage] = React.useState(10)
  const [githubMembers, setGithubMembers] = React.useState(null)
  const [membersSearch, setMembersSearch] = React.useState('')
  const [displayedGithubTeams, setDisplayedGithubTeams] = React.useState(null)
  const [githubTeams, setGithubTeams] = React.useState(null)
  const [teamsSearch, setTeamsSearch] = React.useState('')
  const [teamsPage, setTeamsPage] = React.useState(0)
  const [teamsPerPage, setTeamsPerPage] = React.useState(10)
  const [error, setError] = React.useState(null)
  const [newRepoName, setNewRepoName] = React.useState('')
  const [processingRequest, setProcessingRequest] = React.useState(false)

  const adoAccessToken = localStorage.getItem('ado_access_token')
  const adoRefreshToken = localStorage.getItem('ado_refresh_token')

  const githubAccessToken = localStorage.getItem('github_access_token')
  const githubRefreshToken = localStorage.getItem('github_refresh_token')

  const queryParams = new URLSearchParams(window.location.search)

  // TODO Interact with ADO: https://stackoverflow.com/a/64059671

  const adoAuthorize = () => {
    if (localStorage.getItem('ado_state') === null) {
      console.log('Setting ADO state')

      let randomArray = new Uint32Array(24)
      window.crypto.getRandomValues(randomArray)
      const state = randomArray.join('')

      localStorage.setItem('ado_state', state)
    }

    console.log('Getting ADO app ID...')
    axios.get('/api/ado/config')
    .then((res) => {
      const adoAppId = res.data.app_id

      console.log('Authorizing...')
      let adoQueryParams = new URLSearchParams({
        client_id: adoAppId,
        redirect_uri: baseUrl + "/api/ado/authorize",
        response_type: "Assertion",
        scope: ["vso.build_execute", "vso.release_manage", "vso.variablegroups_manage"].join(" "),
        state: localStorage.getItem('ado_state')
      })
      window.location.href = "https://app.vssps.visualstudio.com/oauth2/authorize?" + adoQueryParams.toString()
    })
    .catch((err) => {
      console.error('Error retrieving ADO config:', err.response)
    })
  }

  const adoRefreshAccessToken = React.useCallback(() => {
    localStorage.removeItem('ado_access_token')
    localStorage.removeItem('ado_expires_in')

    if (adoRefreshToken) {
      console.log('Attempting token refresh')
      window.location.href = window.location.origin + '/api/ado/authorize?refresh_token=' + adoRefreshToken
    } else {
      console.log('Restarting auth flow')
      adoAuthorize()
    }
  }, [adoRefreshToken])

  const handleChangeMembersPage = (e, newPage) => {
    setMembersPage(newPage)
    setDisplayedGithubMembers(githubMembers.slice(membersPerPage * newPage, membersPerPage * (newPage + 1)))
  }

  const handleChangeMembersPerPage = (e) => {
    let newValue = parseInt(e.target.value, 10)
    setMembersPerPage(newValue)
    setMembersPage(0)
    setDisplayedGithubMembers(githubMembers.slice(0, newValue))
  }

  const handleChangeTeamsPage = (e, newPage) => {
    setTeamsPage(newPage)

    let teamsList = githubTeams
    if (teamsSearch) {
      teamsList = githubTeams.filter((val) => {return val.name.match(new RegExp(teamsSearch, 'i'))})
    }
    setDisplayedGithubTeams(teamsList.slice(teamsPerPage * newPage, teamsPerPage * (newPage + 1)))
  }

  const handleChangeTeamsPerPage = (e) => {
    let newValue = parseInt(e.target.value, 10)
    setTeamsPerPage(newValue)
    setTeamsPage(0)

    let teamsList = githubTeams
    if (teamsSearch) {
      teamsList = githubTeams.filter((val) => {return val.name.match(new RegExp(teamsSearch, 'i'))})
    }
    setDisplayedGithubTeams(teamsList.slice(0, newValue))
  }

  const handleTeamsSearchChange = (e) => {
    setTeamsSearch(e.target.value)

    let teamsList = githubTeams
    if (e.target.value) {
      teamsList = githubTeams.filter((val) => {return val.name.match(new RegExp(e.target.value, 'i'))})
    }

    setDisplayedGithubTeams(teamsList.slice(teamsPerPage * teamsPage, teamsPerPage * (teamsPage + 1)))
  }

  const githubAuthorize = () => {
    if (localStorage.getItem('github_state') === null) {
      console.log('Setting state')

      let randomArray = new Uint32Array(24)
      window.crypto.getRandomValues(randomArray)
      const state = randomArray.join('')

      localStorage.setItem('github_state', state)
    }

    console.log('Getting Github client ID...')
    axios.get('/api/github/config')
    .then((res) => {
      const githubClientId = res.data.client_id

      console.log('Authorizing...')
      let githubQueryParams = new URLSearchParams({
        client_id: githubClientId,
        redirect_uri: baseUrl + "/api/github/authorize",
        state: localStorage.getItem('github_state')
      })
      window.location.href = "https://github.com/login/oauth/authorize?" + githubQueryParams.toString()
    })
    .catch((err) => {
      console.error('Error retrieving Github config:', err.response)
    })
  }

  const generateNewRepo = () => {
    const body = {
      owner: 'prologis',
      name: newRepoName,
      private: true
    }

    console.log('Creating new repo...')
    setProcessingRequest(true)

    // TODO May want to move this URL to a lambda
    axios.post(
      'https://api.github.com/repos/prologis/ItInfrastructure.Automation.CICDGoldenCopy/generate',
      body,
      {
        headers: {
          Accept: 'application/vnd.github.baptiste-preview+json',
          Authorization: 'token ' + githubAccessToken
        }
      }
    )
    .then((res) => {
      console.log('Repo successfully created')
      console.log(res.data)
      setNewRepoName('')

      // NOTE: Need a slight delay between when the repo is created and when we query it. Github sometimes returns a 404 when trying to retrieve the README immediately after creation
      setTimeout(() => {
        updateReadme(res.data.contents_url.replace('{+path}', 'README.md'))
      }, 1000)
    })
    .catch((err) => {
      console.error('Error creating new repo')
      console.error(err.response)
      setError(err.response.data.errors[0])
    })
    .finally(() => {
      setProcessingRequest(false)
    })
  }

  const updateReadme = (contentsUrl) => {
    // TODO If we want to get really fancy, update the README with the ADO build badge
    const config = {
      headers: {
        Accept: 'application/vnd.github.v3+json',
        Authorization: 'token ' + githubAccessToken
      }
    }

    axios.get(contentsUrl, config)
    .then((res) => {
      console.log('Got current README file')

      const body = {
        message: "Updating README",
        content: window.btoa("# " + newRepoName),
        sha: res.data.sha
      }

      axios.put(contentsUrl, body, config)
      .then(() => {
        console.log('README updated')
      })
      .catch((err2) => {
        console.error('Error while updating README')
        console.error(err2)
        console.error(err2.response)
      })
    })
    .catch((err) => {
      console.error('Error retrieving current README')
      console.error(err)
      console.error(err.response)
    })
  }

  const githubRefreshAccessToken = React.useCallback(() => {
    localStorage.removeItem('github_access_token')
    localStorage.removeItem('github_expires_in')

    if (githubRefreshToken) {
      console.log('Attempting token refresh')
      window.location.href = window.location.origin + '/api/github/authorize?refresh_token=' + githubRefreshToken
    } else {
      console.log('Restarting auth flow')
      githubAuthorize()
    }
  }, [githubRefreshToken])

  const getOrgMembers = React.useCallback((resultsPage = 1) => {
    axios.get(
      'https://api.github.com/orgs/prologis/members?page=' + resultsPage,
      {
        headers: {
          Accept: 'application/vnd.github.v3+json',
          Authorization: 'token ' + githubAccessToken
        }
      }
    )
    .then((res) => {
      if(res.data.length > 0) {
        setGithubMembers((members) => members.concat(res.data))
        getOrgMembers(resultsPage + 1)
      }
    })
    .catch((err) => {
      console.error('Error getting members')
      console.error(err)
      console.error(err.response)
      if (err.response.status === 401) {
        // Check for refresh token, try using it
        githubRefreshAccessToken()
      } else {
        setError(err.response.data.message)
      }
    })
  }, [githubAccessToken, githubRefreshAccessToken, setError])

  const getOrgTeams = React.useCallback((resultsPage = 1) => {
    axios.get(
      'https://api.github.com/orgs/prologis/teams?page=' + resultsPage,
      {
        headers: {
          Accept: 'application/vnd.github.v3+json',
          Authorization: 'token ' + githubAccessToken
        }
      }
    )
    .then((res) => {
      if(res.data.length > 0) {
        setGithubTeams((teams) => teams.concat(res.data))
        getOrgTeams(resultsPage + 1)
      }
    })
    .catch((err) => {
      console.error('Error getting members')
      console.error(err.response)
      setError(err.response.data.message)
      // TODO See what error message occurs when access token expires
    })
  }, [githubAccessToken, setError])

  React.useEffect(() => {
    // Don't do anything else if there's an error
    if (error !== null) {
      return
    }

    if (queryParams.has('error_description')) {
      setError(queryParams.get('error_description'))
      if (queryParams.get('error') === 'bad_refresh_token') {
        // Clear local storage, restart authorization
        localStorage.clear()
        githubAuthorize()
      }
      return
    }

    if (queryParams.has('access_token')) {
      // Determine if this is for Github or ADO
      if (queryParams.get('token_type') === 'jwt-bearer') {
        console.log('Setting ADO access token')

        localStorage.setItem('ado_access_token', queryParams.get('access_token'))
        localStorage.setItem('ado_expires_in', queryParams.get('expires_in'))
        localStorage.setItem('ado_refresh_token', queryParams.get('refresh_token'))
        localStorage.setItem('ado_scope', queryParams.get('scope'))
        localStorage.setItem('ado_token_type', queryParams.get('token_type'))
        localStorage.removeItem('ado_state')
      } else {
        console.log('Setting Github access token')

        localStorage.setItem('github_access_token', queryParams.get('access_token'))
        localStorage.setItem('github_expires_in', queryParams.get('expires_in'))
        localStorage.setItem('github_refresh_token', queryParams.get('refresh_token'))
        localStorage.setItem('github_refresh_token_expires_in', queryParams.get('refresh_token_expires_in'))
        localStorage.removeItem('github_state')
      }

      // Reload the page without the query parameters
      window.location.href = window.location.origin
      return
    }

    if (adoAccessToken === null) {
      if (adoRefreshToken) {
        adoRefreshAccessToken()
      } else {
        adoAuthorize()
      }

      return
    }

    if (githubAccessToken === null) {
      if (githubRefreshToken) {
        githubRefreshAccessToken()
      } else {
        githubAuthorize()
      }

      return
    }

    // Get a list of users and teams, for assigning permissions to new repo
    if (githubMembers === null) {
      setGithubMembers([])
      getOrgMembers()
    } else {
      if (githubMembers.length > 0 && displayedGithubMembers === null) {
        setDisplayedGithubMembers(githubMembers.slice(0, membersPerPage))
      }
    }

    if (githubTeams === null) {
      setGithubTeams([])
      getOrgTeams()
    } else {
      if (githubTeams.length > 0 && displayedGithubTeams === null) {
        setDisplayedGithubTeams(githubTeams.slice(0, teamsPerPage))
      }
    }
  }, [adoAccessToken, adoRefreshToken, githubAccessToken, displayedGithubMembers, displayedGithubTeams, error, githubMembers, githubTeams, membersPerPage, queryParams, githubRefreshToken, teamsPerPage, adoRefreshAccessToken, getOrgMembers, getOrgTeams, githubRefreshAccessToken])

  return (
    <React.Fragment>
      <Grid container spacing={2} justify="center">
        {githubAccessToken &&
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.centered}>Hello there</Typography>
          </Grid>
        }
        {error !== null &&
          <Grid item xs={12}>
            <Typography color="error" className={classes.centered}>!!! {error} !!!</Typography>
          </Grid>
        }
        <Grid item xs={12}>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item>
              <TextField
                label="Repo Name"
                variant="outlined"
                onChange={(e) => {setNewRepoName(e.target.value)}}
                onKeyPress={(e) => {
                  if(e.key === 'Enter' && newRepoName.length > 0) {
                    generateNewRepo()
                  }
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={newRepoName.length === 0}
                onClick={generateNewRepo}
              >
                {processingRequest ? <CircularProgress size="2em" /> : "Create"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={adoAuthorize}
              >
                Test ADO
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {displayedGithubMembers &&
          <Grid item xs={6}>
            <Paper variant="outlined">
              <List className={classes.list} subheader={<ListSubheader color="primary">Members</ListSubheader>}>
                <ListItem>
                  <Grid container>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        placeholder="Search..."
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TablePagination
                        component="div"
                        count={githubMembers.length}
                        page={membersPage}
                        onChangePage={handleChangeMembersPage}
                        rowsPerPage={membersPerPage}
                        onChangeRowsPerPage={handleChangeMembersPerPage}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                {displayedGithubMembers.map((val, i, arr) => {
                  return (
                    <ListItem key={i} divider={i + 1 < arr.length}>
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={val.login}
                        secondary="blah"
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Paper>
          </Grid>
        }
        {displayedGithubTeams &&
          <Grid item xs={6}>
            <Paper variant="outlined">
              <List className={classes.list} subheader={<ListSubheader color="primary">Teams</ListSubheader>}>
                <ListItem>
                  <Grid container>
                    <Grid item xs={4}>
                      <TextField
                        value={teamsSearch}
                        onChange={handleTeamsSearchChange}
                        variant="outlined"
                        placeholder="Search..."
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TablePagination
                        component="div"
                        count={githubTeams.length}
                        page={teamsPage}
                        onChangePage={handleChangeTeamsPage}
                        rowsPerPage={teamsPerPage}
                        onChangeRowsPerPage={handleChangeTeamsPerPage}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                {displayedGithubTeams.map((val, i, arr) => {
                  return (
                    <ListItem key={i} divider={i + 1 < arr.length}>
                      <ListItemAvatar>
                        <Avatar>
                          <GroupIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={val.name}
                        secondary="blah"
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Paper>
          </Grid>
        }
      </Grid>
    </React.Fragment>
  );
}
